import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useReducer, useState } from "react";
import { faShield, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";

function Register({ edit, studentInfo }) {
  const [values, setValues] = useState(null);
  const { studentID } = useParams();
  const navigate = useNavigate();
  const [hide, setHide] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    values,
  });
  const watchGovernorate = watch("governorate");
  const watchLevel = watch("stage");
  function reducer(state, action) {
    switch (action.type) {
      case "setStates": {
        return {
          ...state,
          states: action.payload,
        };
      }
      case "setGroups": {
        return {
          ...state,
          groups: action.payload,
        };
      }
      case "setSelectedState": {
        return {
          ...state,
          selectedState: action.payload,
        };
      }
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }
      case "setSubmitLoading": {
        return {
          ...state,
          submitLoading: action.payload,
        };
      }
      case "setNoCode": {
        return {
          ...state,
          noCode: action.payload,
        };
      }
      case "setSubmitEditStudent": {
        return {
          ...state,
          submitEditStudent: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    hide: false,
    states: [],
    groups: [],
    selectedState: "",
    errors: {},
    submitLoading: false,
    noCode: false,
    submitEditStudent: {
      flag: "",
      dependency: false,
      data: {},
    },
  });
  useEffect(() => {
    const stateSelector = document.querySelector(".state-selector");
    stateSelector.addEventListener("change", function handleStateSelected(e) {
      dispatch({
        type: "setSelectedState",
        payload: e.target.selectedIndex,
      });
    });
    async function fetchData() {
      try {
        const res = await axios(process.env.REACT_APP_GOVERNORATES_API);
        const data = await res.data;

        dispatch({
          type: "setStates",
          payload: data.states,
        });
        dispatch({
          type: "setGroups",
          payload: data.groups,
        });
      } catch (error) {
        toast.error(
          "An error has happened while retrieving the governorates data, please try again!"
        );
      }
    }
    fetchData();
  }, []);

  const [StudentEditSuccess, StudentEditErrors, StudentEditLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_INFO_API,
    "POST",
    state.submitEditStudent.flag,
    state.submitEditStudent.dependency,
    state.submitEditStudent.data,
    true
  );

  useEffect(() => {
    if (StudentEditSuccess) {
      reset();
      navigate(-1);
    }
  }, [StudentEditSuccess]);

  useEffect(() => {
    if (studentInfo) {
      dispatch({
        type: "setSelectedState",
        payload: studentInfo?.data?.studentinfo?.selected_state,
      });
      setValue("governorate", studentInfo?.data?.studentinfo?.governorate);
      setValue("section", studentInfo?.data?.studentinfo?.section);

      //setValue("stage", studentInfo?.data?.studentinfo?.stage);
      // delete studentInfo?.data?.studentinfo?.governorate;
      //delete studentInfo?.data?.studentinfo?.stage;
      setValues(studentInfo.data.studentinfo);
    }
  }, [setValue, studentInfo]);

  console.log(watchLevel, "jjj");

  const onSubmit = (data) => {
    if (state.noCode && data.activation_code) {
      delete data.activation_code;
    }

    const studentData = edit
      ? {
          ...data,
          section:
            +data.stage === 2 || +data.stage === 3
              ? data.section
              : +data.stage === 1
              ? null
              : "",
          year: "2025",
          id: studentID,
        }
      : {
          ...data,
          profile_pic: data?.profile_pic[0] || null,
          section:
            +data.stage === 2 || +data.stage === 3
              ? data.section
              : +data.stage === 1
              ? null
              : "",
          year: "2025",
        };

    if (edit) {
      dispatch({
        type: "setSubmitEditStudent",
        payload: {
          flag: "AddEditStudent",
          dependency: !state.submitEditStudent.dependency,
          data: studentData,
        },
      });
    } else {
      dispatch({
        type: "setSubmitLoading",
        payload: true,
      });

      async function postData() {
        try {
          const res = await axios.post(
            process.env.REACT_APP_REGISTER_API,
            studentData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          const data = await res.data;
          toast.success(data.message, { duration: 50000 });
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          !edit && navigate("/signin");
          reset();
        } catch (error) {
          dispatch({
            type: "setErrors",
            payload: error.response.data.errors,
          });
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          toast.error(
            "An error has happened while creating a new account, please revise your information!"
          );
        }
      }
      postData();
    }
  };

  return (
    <section className=" w-full flex min-h-screen flex-col items-center justify-center gap-12 py-12 md:gap-6 ">
      {!edit && (
        <>
          <HelmetTags
            title="Create new account | Mr.Mohamed Salama"
            description="Create new account | Mr.Mohamed Salama"
            index
          ></HelmetTags>

          <div className="flex flex-col items-center justify-center gap-4">
            <img
              className="h-[55px] w-auto cursor-pointer "
              src="https://api.our-matrix.com/uploads/assets/logo.png"
              alt="logo"
              onClick={() => navigate("/")}
            />
            <h1 className="text-2xl font-bold">Sign up</h1>
            <p className="text-base opacity-80">
              Enter your details and create an account.{" "}
            </p>
          </div>
        </>
      )}

      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
      >
        {/** Name filed */}
        <div className="flex w-full items-start justify-between gap-16 md:gap-6 md:flex-col ">
          {/** First Name filed */}
          <div className=" flex w-1/3 flex-col items-start justify-center gap-2  md:w-full">
            <label htmlFor="first_name"> First Name</label>
            <input
              className="signin-inputs w-full "
              type="text"
              id="first_name"
              placeholder="First Name"
              name="first_name"
              autoComplete="on"
              {...register("first_name", {
                required: true,
                pattern: /^[A-Za-z]+$/,
                maxLength: 25,
                minLength: 3,
              })}
            />
            {errors.first_name && (
              <p className="pt-2 text-xs text-red-500">
                {errors.first_name.type === "required" &&
                  "This field is required."}
                {errors.first_name.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.first_name.type === "maxLength" &&
                  "Available name max length is 25."}
                {errors.first_name.type === "minLength" &&
                  " Please enter at least 3 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.first_name && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.first_name[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.first_name && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.first_name[0]}
                  </p>
                )
            }
          </div>
          {/** Second Name filed */}
          <div className=" flex w-1/3 flex-col items-start justify-center gap-2  md:w-full">
            <label htmlFor="second_name"> Second Name</label>
            <input
              className="signin-inputs  w-full "
              type="text"
              id="second_name"
              placeholder="Second Name"
              name="second_name"
              autoComplete="on"
              {...register("second_name", {
                required: true,
                pattern: /^[A-Za-z]+$/,
                maxLength: 25,
                minLength: 3,
              })}
            />
            {errors.second_name && (
              <p className="pt-2 text-xs text-red-500">
                {errors.second_name.type === "required" &&
                  "This field is required."}
                {errors.second_name.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.second_name.type === "maxLength" &&
                  "Available name max length is 25."}
                {errors.second_name.type === "minLength" &&
                  " Please enter at least 3 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.second_name && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.second_name[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.second_name && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.second_name[0]}
                  </p>
                )
            }
          </div>

          {/** Third and Fourth Names filed */}

          {/** Third Name filed */}

          <div className=" flex w-1/3 flex-col items-start justify-center gap-2  md:w-full">
            <label htmlFor="third_name"> Third Name</label>
            <input
              className="signin-inputs w-full "
              type="text"
              id="third_name"
              placeholder="Third Name"
              name="third_name"
              autoComplete="on"
              {...register("third_name", {
                required: true,
                pattern: /^[A-Za-z]+$/,
                maxLength: 25,
                minLength: 3,
              })}
            />
            {errors.third_name && (
              <p className="pt-2 text-xs text-red-500">
                {errors.third_name.type === "required" &&
                  "This field is required."}
                {errors.third_name.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.third_name.type === "maxLength" &&
                  "Available name max length is 25."}
                {errors.third_name.type === "minLength" &&
                  " Please enter at least 3 letters."}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.third_name && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.third_name[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.third_name && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.third_name[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** Username and Email fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
          {/** Email field */}
          <div
            className={`flex w-full flex-col items-start justify-center  gap-2 md:w-full`}
          >
            <label htmlFor="email"> Email</label>

            <input
              className="signin-inputs  w-full "
              type="text"
              id="email"
              placeholder="Email"
              name="email"
              autoComplete="on"
              {...register("email", {
                required: true,
                pattern: /^[A-z][A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email && (
              <p className="pt-2 text-xs text-red-500">
                {errors.email.type === "required" && "This field is required."}
                {errors.email.type === "pattern" && " Invalid Email Address."}
              </p>
            )}
            {
              //!-------server errors -----
              state.errors && state.errors.email && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.email[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.email && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.email[0]}
                  </p>
                )
            }
          </div>
        </div>
        {/** Password and Father's Job fileds */}
        {!edit && (
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
            {/** Password  */}

            <div className="w-1/2 md:w-full">
              <div className="relative flex flex-col items-start justify-center gap-2 ">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  className="signin-inputs w-full"
                  type={`${hide ? "text" : "password"}`}
                  placeholder="Password"
                  name="password"
                  autoComplete="on"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />
                {hide ? (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                    icon={faEyeSlash}
                  />
                )}
              </div>
              {errors.password && (
                <p className="pt-4 text-xs text-red-500">
                  {errors.password.type === "required" &&
                    "This field is required."}
                  {errors.password.type === "maxLength" &&
                    "password Max length is 25 char."}
                  {errors.password.type === "minLength" &&
                    "Password Min length is 6 char."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.password && (
                  <p className="pt-2 text-xs text-red-500">
                    {state.errors.password[0]}
                  </p>
                )
              }
            </div>

            {/** Confirm Password  */}
            <div className="w-1/2 md:w-full">
              <div className="relative flex flex-col items-start justify-center gap-2 ">
                <label htmlFor="password_confirmation">Confirm Password</label>
                <input
                  id="password_confirmation"
                  className="signin-inputs w-full"
                  type={`${hide ? "text" : "password"}`}
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  autoComplete="on"
                  {...register("password_confirmation", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                    validate: (value) => value === getValues("password"),
                  })}
                />
                {hide ? (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                    icon={faEyeSlash}
                  />
                )}
              </div>
              {errors.password_confirmation && (
                <p className="pt-4 text-xs text-red-500">
                  {errors.password_confirmation.type === "required" &&
                    "This field is required."}
                  {errors.password_confirmation.type === "maxLength" &&
                    "password_confirmation Max length is 25 char."}
                  {errors.password_confirmation.type === "minLength" &&
                    "Password Min length is 6 char."}
                  {errors.password_confirmation.type === "validate" &&
                    "Passwords don't match."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.password_confirmation && (
                  <p className="pt-2 text-xs text-red-500">
                    {state.errors.password_confirmation[0]}
                  </p>
                )
              }
            </div>
          </div>
        )}

        {/** student and parents mobile numbers fields */}
        <div className="flex w-full items-start justify-between gap-16 md:gap-6 md:flex-col ">
          {/** Student Mobile field */}

          <div className="w-1/3 md:w-full">
            <div className=" relative flex w-full flex-col items-start justify-center gap-2">
              <label htmlFor="mobile">Student Mobile</label>
              <input
                className="signin-inputs w-full "
                type="number"
                inputMode="numeric"
                id="mobile"
                placeholder="Student Mobile"
                name="mobile"
                min="1"
                autoComplete="on"
                {...register("mobile", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                  min: 1,
                  validate: (value) =>
                    value !== getValues("parent_mobile") &&
                    value !== getValues("parent_mobile2"),
                })}
              />

              <FontAwesomeIcon
                className="absolute right-2 bottom-2 h-4 w-4 "
                icon={faWhatsapp}
              />
            </div>
            {errors.mobile && (
              <p className="pt-4 text-xs text-red-500">
                {errors.mobile.type === "required" && "This field is required."}
                {errors.mobile.type === "pattern" && " Invalid Mobile Number."}
                {errors.mobile.type === "maxLength" &&
                  " Invalid Mobile Number."}
                {errors.mobile.type === "min" && " Invalid Mobile Number."}
                {errors.mobile.type === "validate" &&
                  "Please, Don't Enter the Same Mobile Number."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.mobile && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.mobile[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.mobile && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.mobile[0]}
                  </p>
                )
            }
          </div>
          {/** Father's Mobile field */}

          <div className="w-1/3 md:w-full">
            <div className=" relative flex w-full flex-col items-start justify-center gap-2">
              <label htmlFor="parent_mobile">Father's Mobile</label>
              <input
                className="signin-inputs w-full "
                type="number"
                inputMode="numeric"
                id="parent_mobile"
                placeholder="Father's Mobile"
                name="parent_mobile"
                autoComplete="on"
                min="1"
                {...register("parent_mobile", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                  min: 1,
                  validate: (value) =>
                    value !== getValues("mobile") &&
                    value !== getValues("parent_mobile2"),
                })}
              />

              <FontAwesomeIcon
                className="absolute right-2 bottom-2 h-4 w-4 "
                icon={faWhatsapp}
              />
            </div>
            {errors.parent_mobile && (
              <p className="pt-4 text-xs text-red-500">
                {errors.parent_mobile.type === "required" &&
                  "This field is required."}
                {errors.parent_mobile.type === "pattern" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile.type === "maxLength" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile.type === "min" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile.type === "validate" &&
                  "Please, Don't Enter the Same Mobile Number."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.parent_mobile && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.parent_mobile[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.parent_mobile && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.parent_mobile[0]}
                  </p>
                )
            }
          </div>
          {/** Mother's Mobile field */}

          <div className="w-1/3 md:w-full">
            <div className=" relative flex w-full flex-col items-start justify-center gap-2">
              <label htmlFor="parent_mobile2">Mother's Mobile</label>
              <input
                className="signin-inputs w-full "
                type="number"
                inputMode="numeric"
                id="parent_mobile2"
                placeholder="Mother's Mobile"
                name="parent_mobile2"
                autoComplete="on"
                min="1"
                {...register("parent_mobile2", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                  min: 1,
                  validate: (value) =>
                    value !== getValues("mobile") &&
                    value !== getValues("parent_mobile"),
                })}
              />

              <FontAwesomeIcon
                className="absolute right-2 bottom-2 h-4 w-4 "
                icon={faWhatsapp}
              />
            </div>
            {errors.parent_mobile2 && (
              <p className="pt-4 text-xs text-red-500">
                {errors.parent_mobile2.type === "required" &&
                  "This field is required."}
                {errors.parent_mobile2.type === "pattern" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile2.type === "maxLength" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile2.type === "min" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile2.type === "validate" &&
                  "Please, Don't Enter the Same Mobile Number."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.parent_mobile2 && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.parent_mobile2[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.parent_mobile2 && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.parent_mobile2[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** Governorate and City fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
          {/** Governorate filed */}

          <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full ">
            <label htmlFor="governorate">Governorate</label>
            <select
              className="state-selector"
              name="governorate"
              id="governorate"
              {...register("governorate", { required: true })}
            >
              <option hidden value=""></option>
              {state.states.map((state) => (
                <option key={state.id} value={state.state}>
                  {state.state}
                </option>
              ))}
            </select>
            {errors.governorate && (
              <p className="pt-2 text-xs text-red-500">
                {errors.governorate.type === "required" &&
                  "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.governorate && (
                <p className="text-xs text-red-500  ">
                  {state.errors.governorate[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.governorate && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.governorate[0]}
                  </p>
                )
            }
          </div>

          {/** City filed */}
          <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
            {watchGovernorate ? (
              <>
                <label htmlFor="area">City</label>
                <select
                  name="area"
                  id="area"
                  {...register("area", { required: true })}
                >
                  {state.states[state.selectedState - 1]?.cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.city_name_en}
                    </option>
                  ))}
                </select>

                {errors.area && (
                  <p className="text-xs text-red-500  ">
                    {errors.area.type === "required" &&
                      "This field is required."}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.area && (
                    <p className="text-xs text-red-500  ">
                      {state.errors.area[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.area && (
                      <p className="pt-2 text-xs text-red-500">
                        {StudentEditErrors?.response?.data.errors.area[0]}
                      </p>
                    )
                }
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        {/** Stage  filed */}
        <div className="flex w-full items-end justify-between gap-16 md:flex-col md:items-start md:gap-6 ">
          {/** Stage Level  */}

          <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
            <label htmlFor="stage">Stage</label>
            <select
              name="stage"
              id="stage"
              {...register("stage", { required: true })}
            >
              <option hidden></option>
              <option value="1">First</option>
              <option value="2">Second</option>
              <option value="3">Third</option>
            </select>
            {errors.stage && (
              <p className="pt-2 text-xs text-red-500">
                {errors.stage.type === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.stage && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.stage[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.stage && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.stage[0]}
                  </p>
                )
            }
          </div>

          {/** Scientific or Literary fields */}

          <div className="flex w-1/2 flex-col  items-center md:w-full ">
            {+watchLevel === 2 || +watchLevel === 3 ? (
              <>
                <div className="flex w-full items-end justify-between gap-8 pb-1 ">
                  {/** Scientific */}

                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="scientific"
                      value="scientific"
                      {...register("section", { required: true })}
                    />
                    <label className="cursor-pointer" htmlFor="scientific">
                      Scientific
                    </label>
                  </div>
                  {/** Literary  */}

                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="literary"
                      value="literary"
                      {...register("section", { required: true })}
                    />
                    <label className="cursor-pointer" htmlFor="literary">
                      Literary
                    </label>
                  </div>
                </div>
                {errors.section && (
                  <p className="pt-2 text-xs text-red-500">
                    {errors.section.type === "required" &&
                      "This field is required."}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.section && (
                    <p className="pt-2 text-xs text-red-500">
                      {state.errors.section[0]}
                    </p>
                  )
                }{" "}
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.section && (
                      <p className="pt-2 text-xs text-red-500">
                        {StudentEditErrors?.response?.data.errors.section[0]}
                      </p>
                    )
                }
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* <div className="flex w-full items-end justify-between gap-16 md:flex-col md:items-start md:gap-6 ">
          {watchLevel === "3" || watchLevel === 3 ? (
            <div
              className={` flex  w-full flex-col items-start justify-center  gap-2 md:w-full`}
            >
              <label className="w-full truncate" htmlFor="year">
                Year
              </label>
              <select
                name="year"
                id="year"
                {...register("year", { required: true })}
              >
                <option value="2024">حجز 2023\2024</option>
                <option value="2025">حجز 2024\2025</option>
              </select>
              {errors.year && (
                <p className="pt-2 text-xs text-red-500">
                  {errors.year.type === "required" && "This field is required."}

                  {errors.year.type === "maxLength" &&
                    "Available username max length is 180."}
                  {errors.year.type === "minLength" &&
                    " Please enter at least 20 letters."}
                  {errors.year.type === "validate" &&
                    " Please enter a  Valid Facebook Link."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.year && (
                  <p className="pt-2 text-xs text-red-500">
                    {state.errors.year[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.year && (
                    <p className="pt-2 text-xs text-red-500">
                      {StudentEditErrors?.response?.data.errors.year[0]}
                    </p>
                  )
              }
            </div>
          ) : null}
        </div> */}

        {/**  Profile Picture  */}
        {!edit && (
          <div className="w-full ">
            <label htmlFor="image"> Profile Picture </label>

            <input
              id="profile_pic"
              className="signin-inputs  w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="profile_pic"
              {...register("profile_pic", {
                required: true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.profile_pic && (
              <p className="pt-4 text-xs text-red-500">
                {errors.profile_pic.type === "required" &&
                  "This field is required."}
                {errors.profile_pic.type === "validate" &&
                  "Max Image Size is 5MB."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.profile_pic && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.profile_pic[0]}
                </p>
              )
            }
          </div>
        )}

        {/** Submit Button */}

        <button
          disabled={!isValid || state.submitLoading}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {state.submitLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : edit ? (
            "Edit Student"
          ) : (
            "Sign up"
          )}
        </button>
      </form>
      {!edit && (
        <p className="text-sm ">
          Already have an account?{" "}
          <Link className="font-bold" to="/signin">
            Sign in.
          </Link>
        </p>
      )}
    </section>
  );
}

export default Register;
